import React, { useEffect, useState } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../Auth';
import { Box } from '@mui/material';

function AdminBuyEdit() {
  let wonAudio = new Audio('./songs/win.wav')
  let loseAudio = new Audio('./songs/lose.wav')
  const { state } = useLocation();
  const navigate = useNavigate();
  const {authTokens, userType, selLotteryDate} = useAuth()
  var selDayPart = selLotteryDate?.datePart
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])

  const [updateUser, setUpdateUser] = useState(0)

  const deleteHistory = async(id) => {
        if(id !== ''){
            let delUrl = mainUrl+'api.php?op=deleteHistory&id='+id
            await axios({
            method: 'get',
            url: delUrl
            }).then(async() => {
                setUpdateUser(updateUser+1)
            })
        }
    }
  const [takeLoad, setTakeLoad] = useState(0)

    /* ---------- digit edit screen ------ */
    
    const [result, setResult] = useState('')
    const [errorMes, setErrorMes] = useState(state?.message)
    const [success, setSuccess] = useState(state?.success)

    const defineSplitlens = (data) => {
      if(data === ''){
        return 0
      } else {
        if(data?.includes('R')){
          return 3
        } else if(data?.includes('A') ||
        data?.includes('N') ||
        data?.includes('W') ||
        data?.includes('X')
        ) {
          return 1
        } else if(data?.includes('Z')) {
          var splZ = data?.split('Z')
          var last = splZ?.[splZ?.length-1]?.length
          var finalLen = data?.length - last
          return finalLen
        } else if(data?.includes('S') ||
          data?.includes('M')) {
          var len = data?.[1] === 'S' || data?.[1] === 'M'?2:1
          return len
        } else {
          return 2
        }
      }
    }

    var splitLen = defineSplitlens(result);

    function checkIfDuplicateExists(arr) {
      return new Set(arr).size !== arr.length
    }

    useEffect(() => {
      var len = result?.[1] === 'S' || result?.[1] === 'M'?2:1
      var checkSt = result?.slice(len)
      var amount = result?.slice(splitLen)
      var spl = result?.split('R')
      var splZ = result?.split('Z')
      var splZZ = result?.includes('ZZ')? result?.split('ZZ') : splZ
      var digits = result?.slice(0,splitLen)
      var newDigit = digits?.replace('Z','')
      var newDig = newDigit?.split('')
      if(checkSt?.includes('S') || checkSt?.includes('M')){
        reset()
      }else if(amount?.includes('S')||amount?.includes('M')||
      amount?.includes('S')||amount?.includes('M')|| amount?.includes('A')||amount?.includes('N')||
      amount?.includes('X')||amount?.includes('W')|| amount?.includes('F')||amount?.includes('B')||
      amount?.includes('P')
      ){
        reset()
      }else if(spl?.length > 2){
        reset()
      }else if(splZZ?.length > 2 || splZZ?.[1]?.includes('Z')){
        reset()
      }else if(result?.includes('Z') && checkIfDuplicateExists(newDig)) {
        reset()
      }else if(result?.includes('Z') && result?.includes('R')) {
        reset()
      }else if(result?.includes('X') && result?.includes('R')) {
        reset()
      }else if(result?.includes('X') && result?.includes('Z')) {
        reset()
      }else if(result?.includes('S') && result?.includes('R')) {
        reset()
      }else if(result?.includes('M') && result?.includes('R')) {
        reset()
      }else if(result?.includes('S') && result?.includes('Z')) {
        reset()
      }else if(result?.includes('M') && result?.includes('Z')) {
        reset()
      }else if(result?.includes('N') && result?.includes('R')) {
        reset()
      }else if(result?.includes('W') && result?.includes('R')) {
        reset()
      }else if(result?.includes('B') && result?.includes('R')) {
        reset()
      }else if(result?.includes('F') && result?.includes('R')) {
        reset()
      }else if(result?.includes('L') && result?.includes('R')) {
        reset()
      }else if(result?.includes('P') && result?.includes('R')) {
        reset()
      }else if(result?.includes('A') && result?.includes('R')) {
        reset()
      }else if(spl?.[0]?.length === 2 && result?.includes('R')){
        if(spl?.[0]?.[0] === spl?.[0]?.[1]){
          reset()
        }
      }else if(result?.includes('R') && result?.length < 3) {
        reset()
      }else if((result?.includes('E') && result?.includes('M')) || (result?.includes('E') && result?.includes('S'))) {
        reset()
      }else if(result?.[0] === 'Z' || result?.[1] === 'Z') {
        reset()
      }else if(result?.split('E').length > 3 || (result?.[0] === 'E' && result?.[1] === 'E') || (result?.[0] === 'E' && result?.[2] === 'E')){
        reset()
      }else if(result?.includes('EE')){
        reset()
      }
    },[result, splitLen])
    
    const onClick = (button) => {
      if(button === "Save") {
        SaveDigit();
      }
      else if(button === "C") {
        //setErrorMes('')
        reset();
      }
      else {
        if(button === 'A' ||
        button === 'N' ||
        button === 'X' ||
        button === 'W'
        
        ) {
          setResult(button)
        } else if(button === 'S' || button === 'M') {
          setResult(result+button)
          if(result?.length > 0){
            if(result?.[0] === '1' ||
              result?.[0] === '2' ||
              result?.[0] === '3' ||
              result?.[0] === '4' ||
              result?.[0] === '5' ||
              result?.[0] === '6' ||
              result?.[0] === '7' ||
              result?.[0] === '8' ||
              result?.[0] === '9'
              ) {
                reset()
              } else {
                setResult(result+button)
            }
          }
          if(result?.length > 1){
            if(result?.[2] === '1' ||
              result?.[2] === '2' ||
              result?.[2] === '3' ||
              result?.[2] === '4' ||
              result?.[2] === '5' ||
              result?.[2] === '6' ||
              result?.[2] === '7' ||
              result?.[2] === '8' ||
              result?.[2] === '9'
              ) {
                setResult(result+button)
              } else {
                reset()
            }
          }
        } else if (button === "F" ||
          button === "L" ||
          button === "B" ||
          button === "P"
        ) {
          if(result?.length > 0) {
            if(result?.[0] === '0' ||
            result?.[0] === '1' ||
            result?.[0] === '2' ||
            result?.[0] === '3' ||
            result?.[0] === '4' ||
            result?.[0] === '5' ||
            result?.[0] === '6' ||
            result?.[0] === '7' ||
            result?.[0] === '8' ||
            result?.[0] === '9'
            ) {
              setResult(result?.[0]+button)
            } else {
              reset()
            }
          } else {
            reset()
          }
        } else if(button === 'R') {
          setResult(result+button)
        } else if(button === 'Z') {
          setResult(result+button)
        } else if(button === 'E'){
          if(result?.length > 0){
            if(result?.[0] === 'E'){
              var substringBetweenEs = result?.split('E')?.[1]
              var chunks = substringBetweenEs?.match(/\d{2}/g);
              var formattedSubstring = chunks?.join(',');
              if(formattedSubstring === undefined){
                reset()
              }else {
                var wantstring = result.replace(substringBetweenEs, formattedSubstring);
                setResult(wantstring+button)
              }
            } else {
              setResult(button)
            }
          } else {
            setResult(result+button)
          }
        }
        else {
          setResult(result+button)
        }
      }
    }
    const reset = () => {
      setResult('')
    }

    const SaveDigit = async() => {
      if(state?.id === '' || state?.id === undefined){
        loseAudio.play()
        setErrorMes('ပြန်ပြင်မည့် ဝယ်ဂဏန်းကို ရွေးရန်')
        setSuccess(false)
      }
      else {
        if(state?.username!==''){
          let messageUrl = mainUrl+'api.php?op=updateDigit';
          deleteHistory(state?.id).then(async() => {
            await fetch(messageUrl, {
              method:'post',
              headers:{
                'Content-Type':'application/x-www-form-urlencoded'
              },
              body:"result="+result+"&userid="+state?.username+"&date="+state?.date+"&buy=2&adminid="+userType?.id+'&id='+state?.id+"&part="+selDayPart
            })
          }).then(() => {
            wonAudio.play()
            setResult('')
            setTakeLoad(takeLoad+1)
            setErrorMes(state?.message+' -> '+ result+' ပြင်ပြီး!')
            setSuccess(true)
          })
        } else {
          loseAudio.play()
          setErrorMes('Agent အရင်ရွေးပါ')
          setSuccess(false)
        }
      }
    }

    //var digitStatus = DigitStatus()
    var digitStatus = 'Open'
    
    const numarray = Array(100).fill(0).map((n, i) => { 
      if((n + i) < 10){
        return '0'+String(n+i)
      } else {
        return String(n + i)
      }
    })

    const DefineDigit = (digit, result, numarray, amount, first, second, date, part) => {
        if(result?.includes('R') && !result?.includes('E')){
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var firstFirst = firstSplit?.slice(0,2)
        var reverseDigit = firstFirst?.split("").reverse().join("")
        return [{'digit':firstFirst, 'amount':first, 'date':date, 'part':part},{'digit':reverseDigit, 'amount':second, 'date':date, 'part':part}]
        } else if(digit.includes('P')){
        var newPDigit = digit?.replace('P','')
        var digitinclude = numarray.filter(num => num.includes(newPDigit))
        return digitinclude.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit.includes('A')){
        return ['00','11','22','33','44','55','66','77','88','99'].map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit === 'S'){
        var digitSEven = numarray.filter(num => parseInt(num) % 2 === 0)
        return digitSEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit === 'M'){
        var digitOdd = numarray.filter(num => parseInt(num) % 2 === 1)
        return digitOdd.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit === 'SS'){
        var digitSSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 0)
        return digitSSEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit === 'SM'){
        var digitSMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 0 && parseInt(num?.[1]) % 2 === 1)
        return digitSMEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit === 'MM'){
        var digitMMEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 1)
        return digitMMEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit === 'MS'){
        var digitMSEven = numarray.filter(num => parseInt(num?.[0]) % 2 === 1 && parseInt(num?.[1]) % 2 === 0)
        return digitMSEven.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit.includes('F')){
        var newFDigit = digit?.replace('F','')
        var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
        return digitFFirst.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit.includes('L')){
        var newLDigit = digit?.replace('L','')
        var digitLFirst = numarray.filter(num => num?.[1] === newLDigit)
        return digitLFirst.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit.includes('B')){
        var newBDigit = digit?.replace('B','')
        var digitBFirst = numarray.filter(num => 
            (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.length === 1?
            (parseInt(num?.[0])+parseInt(num?.[1])).toString() === newBDigit : 
            (parseInt(num?.[0])+parseInt(num?.[1])).toString()?.[1] === newBDigit
            )
        return digitBFirst.map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit.includes('W')){
        return ['05','50','16','61','27','72','38','83','49','94'].map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit.includes('N')){
        return ['07','70','18','81','24','42','35','53','69','96'].map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else if(digit?.includes('X')){
        return ['01','10','12','21','23','32','34','43','45','54',
        '56','65','67','76','78','87','89','98','90','09'].map(item => ({'digit':item, 'amount':amount, 'date':date, 'part':part}));
        } else if(digit?.includes('Z')){
        if(digit?.includes('ZZ')){
            var newZZDigit = digit?.replace('ZZ','')
            var newZZArr = newZZDigit?.split('')
            return combinationsTwo(newZZArr).map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        } else {
            var newZDigit = digit?.replace('Z','')
            var newZArr = newZDigit?.split('')
            return combinations(newZArr).map(item => ({'digit':item,'amount':amount, 'date':date, 'part':part}));
        }
        }else if(result?.includes('E')){
          var newEDigit = result?.split('E')?.[1]?.split(',')
          var amountE = result?.split('E')?.[2]
          var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
          var modEArr = []
          if(amountE?.includes('R')){
            newEDigit?.forEach((digit) => {
                if(digit === digit?.split("").reverse().join("")){
                modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
                } else {
                modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
                modEArr?.push({'digit':digit?.split("").reverse().join(""), 'amount':amounts?.[1]})
                }
            })
          } else {
              newEDigit?.forEach((digit) => {
                  modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
              })
          }
          return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'date':date, 'part':part}))
        }
        return [{'digit':digit, 'amount':amount, 'date':date, 'part':part}]
    }
    
    function removeDuplicates(arr) {
        return arr.filter((item,index) => arr.indexOf(item) === index);
    }
    const combinationsTwo = (arr) => {
        let set = new Set(arr);
        
        arr = [...set];
        var digit = []
        
        for(let i = 0; i < arr.length - 1; i++){       
        for(let j = i + 1; j < arr.length; j++){ 
            digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i],arr[i]+''+arr[i],arr[j]+''+arr[j]);
        }     
        } 
        return removeDuplicates(digit)
    }
    
    const combinations = (arr) => {
        let set = new Set(arr);
        
        arr = [...set];
        var digit = []
        
        for(let i = 0; i < arr.length - 1; i++){       
        for(let j = i + 1; j < arr.length; j++){ 
            digit?.push(arr[i]+''+arr[j],arr[j]+''+arr[i]);
        }     
        } 
        return digit
    }

    var newDigit = result?.split('R')
    var firstSplit = newDigit?.[0]

    const digitArrForResult = DefineDigit(result?.slice(0,splitLen), result, numarray, result?.slice(defineSplitlens(result)), result?.includes('R') && result?.split('R')?.[0]?.length > 2? firstSplit?.slice(2):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0');


    // edit screen 

    return (
        <div className='adminScreen'>
        
        <div className='adminTop'>
            <Button
            onClick={() => navigate('/adminbuyhistory')} 
            style={{textDecoration:'none', backgroundColor:'white',
            color:'black', textTransform:'capitalize', padding:'1px 5px'}}
            >
            Back
            </Button>
        </div>
        
        <div style={{display:'flex', overflow:'auto'}}>
            <div style={{padding:10, flex:'0 0 auto', width:410}}>
            
            {/** edit digit screen */}
          
            <div className='adminHomeScreen' style={{marginLeft:0}}>
            
            <div style={{padding:'3px 10px 0px 10px', display:'flex'}}>
              <p>Result: </p> <p style={{color: success?'#1bbc12':'#d8005d', lineHeight:'20px'}}>&nbsp; {errorMes}</p>
            </div>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: "95%"},
              }}
              className='White'
              style={{textAlign:'center'}}
              noValidate
              autoComplete="off"
            >
            <div>
              <TextField
                size='small'
                disabled
                value={result}
                id="outlined-disabled"
                placeholder='Press any digit'
                sx={{
                    "& .MuiInputBase-root.Mui-disabled": {
                        "& > fieldset": {
                            borderColor: "rgba(0,0,0,0.1)",
                            WebkitTextFillColor: "#000000",
                        },
                        WebkitTextFillColor: "#000000",
                        fontSize:27
                    }
                }}
                InputProps={{
                  sx: {
                    "&.MuiInputBase-root.Mui-disabled": { color: "unset" },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "unset",
                    },
                  },
                  autoComplete: 'off'
                }}
              />
            </div>
            </Box>
            <div className='firstrow'>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value="C"
            style={{fontFamily: 'mmfont'}}
            >
            ဖျက်မည်
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "P"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            အပါ
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "R"
            disabled={digitStatus==="Closed"}
            id="digitBox"
            >
            R
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "A"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            အပူး
            </Button>
          </div>
          <div className='firstrow'>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "7"
            disabled={digitStatus==="Closed"}
            >
            7
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "8"
            disabled={digitStatus==="Closed"}
            >
            8
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "9"
            disabled={digitStatus==="Closed"}
            >
            9
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "S"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            စုံ
            </Button>
          </div>
          <div className='firstrow'>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "4"
            disabled={digitStatus==="Closed"}
            >
            4
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "5"
            disabled={digitStatus==="Closed"}
            >
            5
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "6"
            disabled={digitStatus==="Closed"}
            >
            6
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "M"
            disabled={digitStatus==="Closed"}
            style={{fontFamily: 'mmfont'}}
            >
            မ
            </Button>
          </div>
          <div className='firstrow' style={{alignItems:'flex-start', marginTop:-0.5}}>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "1"
            disabled={digitStatus==="Closed"}
            >
            1
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'202%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "0"
            disabled={digitStatus==="Closed"}
            >
            0
            </Button>
            </div>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            onClick={(e) => onClick(e.target.value)}
            value = "2"
            disabled={digitStatus==="Closed"}
            >
            2
            </Button>
            <div style={{width:'24.5%', display:'flex', flexDirection:'column'}}>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'100%'}}
            onClick={(e) => onClick(e.target.value)}
            value = "3"
            disabled={digitStatus==="Closed"}
            >
            3
            </Button>
            <Button
            variant="contained" disableElevation
            className='buttonBox'
            id="digitBox"
            disableRipple
            style={{width:'100%', marginTop:3}}
            onClick={(e) => onClick(e.target.value)}
            value = "00"
            disabled={digitStatus==="Closed"}
            >
            00
            </Button>
            </div>
            <Button
            variant="contained" disableElevation
            className='buttonBox fourthBox'
            disableRipple
            style={{backgroundColor:'#6798c6 !important', fontFamily:'mmfont'}}
            onClick={(e) => onClick(e.target.value)}
            value = "Save"
            disabled={digitStatus==="Closed" || digitArrForResult?.[0]?.digit === '' || digitArrForResult?.[0]?.amount === ''}
            >
            သိမ်းမည်
            </Button>
          </div>
          <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "F"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ထိပ်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "L"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          နောက်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "E"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ငွေတူ
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "B"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ဘရိတ်
          </button>
        </div>
        <div className='firstrow'>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "W"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ပါဝါ
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "N"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          နခတ်
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "X"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ညီကို
          </button>
          <button
          //variant="contained" disableElevation
          className='buttonBox'
          //disableRipple
          onClick={(e) => onClick(e.target.value)}
          value = "Z"
          disabled={digitStatus==="Closed"}
          style={{fontFamily: 'mmfont'}}
          >
          ခွေ
          </button>
          </div>
          </div>
            
          </div>
        </div>
        </div>
    )
}

export default AdminBuyEdit
