import React, {useState, useEffect, useMemo} from 'react'
import './HistoryScreen.css';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';
import { mainUrl } from './url';
import { useAuth } from '../Auth';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ModeEditTwoTone } from '@mui/icons-material';

function ThreeAdminHistoryScreen() {
    const {userType, threeDate} = useAuth()
    const navigate = useNavigate();
    const [history, setHistory] = useState([])
    const [username, setUsername] = useState('')
    const [users, setUsers] = useState([])

    const [updateHis, setUpdateHis] = useState(0)
    const [delMes, setDelMes] = useState('')
    const [open, setOpen] = React.useState(false);
    const [defineId, setDefineId] = useState('')

    const handleClickOpen = (id) => {
        setOpen(true);
        setDefineId(id)
    };
    const handleClose = () => {
        setOpen(false);
    };

    const useNewHistory = (history) => {
      return useMemo(() => {
        if (!history || history.length === 0) return [];
    
        return history.filter((his) => {
          return his?.buy === '2';
        });
      }, [history]);
    };
    const newHistory = useNewHistory(history);

    const deleteHistory = async(id) => {
      if(id !== ''){
          let delUrl = mainUrl+'api.php?op=threedeleteHistory&id='+id
          await axios({
            method: 'get',
            url: delUrl
          }).then(async() => {
              setDelMes('ဝယ်ဂဏန်း ဖျက်ပီးပါပီ')
              setUpdateHis(updateHis+1)
              setOpen(false)
          })
      }
    }
    
    useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryFilter&userid='+username+'&adminid='+userType?.id+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserall&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setHistory(response?.[0]?.['data']['data']['result'])
                        setUsers(response?.[1]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [username, updateHis, userType?.id, threeDate?.dateFrom, threeDate?.dateTo])

    const handleClickEdit = (id) => {
      var historyDetail = history?.filter((history) => history?.id === id)
      navigate('/threebuyedit',{
        state:{
          success:true,
          id:id,
          message:historyDetail?.[0]?.result + ' ဝယ်ကွက်အား ပြင်ဆင်ရန်',
          date: historyDetail?.[0]?.date,
          username:username
        }
      })
    }
    return (
        <div
        style={{display:'flex', 
        justifyContent:'center', paddingBottom:10,
        alignItems:'start'}}
        >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{fontFamily:'mmfont'}}>
          {"ဝယ်ဂဏန်း ဖျက်ရန်"}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{fontFamily:'mmfont'}}>
              ဝယ်ဂဏန်းများနှင့် ပမာဏကို ဖျက်ရန် သေချာလား?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClose} style={{fontFamily:'mmfont'}}>မဖျက်ပါ</Button>
          <Button style={{fontFamily:'mmfont'}} onClick={() => deleteHistory(defineId)}>
              ဖျက်မည်
          </Button>
          </DialogActions>
        </Dialog>
        <div className='historyScreen'>
            <div className='historytitle'>
                <Button className='historylink' onClick= {() => navigate('/threebuyinput')}>
                    <ArrowBackIosIcon color='rgba(0,0,0,0.7)' 
                    style={{color:'rgba(0,0,0,0.7)', 
                    }}/>
                </Button>
                <p style={{fontFamily:'mmfont'}}>ဝယ်ပြီး အကွက်များ</p>
            </div>
            <p style={{fontSize:15, color:'green', marginTop:delMes!==''?3:0, paddingLeft:8}}>{delMes}</p>
            <div className='historytitle'>
                <p style={{marginRight:28, fontWeight:'normal'}}>Agents </p>
                <Select
                size='small'
                sx={{
                  height:30,
                  width:150,
                  color: "white",
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                    border:'2px solid white'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: "white !important",
                  }
                }}
                labelId="select-user"
                id="select-user"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                >
                  {
                    users?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
                    <MenuItem key={index} value={user?.id}>{user?.username}</MenuItem>
                    )
                  }
                </Select>
            </div>
            <div className='tablehead'>
                <p style={{width:50, lineHeight:2}} className='tablebox'>SN</p>
                <p style={{width:'33%', lineHeight:2.2, fontFamily:'mmfont'}} className='tablebox'>ဝယ်ပီး ဂဏန်းများ</p>
                <p style={{width:'47%', lineHeight:2.2, fontFamily:'mmfont'}} className='tablebox'>ဝယ်ခဲ့သော အချိန်</p>
                <p style={{width:50, lineHeight:2}} className='tablebox'>Edit</p>
                <p style={{width:50, lineHeight:2}} className='tablebox'>Del</p>
            </div>
            {
                newHistory?.map((his,index) => (
                    <div className='tabledata'>
                        <p style={{width:50}} className='tablebox'>{index+1}</p>
                        <p style={{width:'33%'}} className='tablebox'>{his?.result}</p>
                        <p style={{width:'47%'}} className='tablebox'>{his?.date?.split(' ')?.[0]?.split('T')?.[0]+' '+his?.date?.split(' ')?.[0]?.split('T')?.[1]}</p>
                        <div className='deltablebox'>
                          <ModeEditTwoTone 
                            onClick={() => handleClickEdit(his?.id)}
                          />
                        </div>
                        <div className='deltablebox'>
                          <DeleteTwoToneIcon 
                            onClick={() => handleClickOpen(his?.id)}
                          />
                        </div>
                    </div>
                ))
            }
        </div>
        </div>
    )
}

export default ThreeAdminHistoryScreen