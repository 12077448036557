import { useEffect, useState } from 'react';
import './App.css';
import DetailScreen from './Screens/DetailScreen';
import HistoryScreen from './Screens/HistoryScreen';
import HomeScreen from './Screens/HomeScreen';
import LoginScreen from './Screens/LoginScreen';
import SelectDateScreen from './Screens/SelectDateScreen';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthContext } from './Auth';
import moment from 'moment-timezone';
import AdminHomeScreen from './Screens/AdminHomeScreen';
import ProfitLossScreen from './Screens/ProfitLossScreen';
import AdminBuyScreen from './Screens/AdminBuyScreen';
import AdminHistoryScreen from './Screens/AdminHistoryScreen';
import DailyScreen from './Screens/DailyScreen';
import HotScreen from './Screens/HotScreen';
import AccountScreen from './Screens/AccountScreen';
import SelectScreen from './SelectScreen';
import ThreeAdminHomeScreen from './Screens/ThreeAdminHomeScreen';
import ThreeSelectDateScreen from './Screens/ThreeSelectDateScreen';
import ThreeAdminBuyScreen from './Screens/ThreeAdminBuyScreen';
import ThreeProfitLossScreen from './Screens/ThreeProfitLossScreen';
import ThreeMonthlyScreen from './Screens/ThreeMontlyScreen';
import ThreeAccountScreen from './Screens/ThreeAccountScreen';
import ThreeHotScreen from './Screens/ThreeHotScreen';
import ThreeAdminHistoryScreen from './Screens/ThreeAdminHistoryScreen';
import ThreeHomeScreen from './Screens/ThreeHomeScreen';
import ThreeAgentSelectDateScreen from './Screens/ThreeAgentSelectDateScreen';
import ThreeHistoryScreen from './Screens/ThreeHistoryScreen';
import ThreeDetailScreen from './Screens/ThreeDetailScreen';
import SearchScreen from './Screens/SearchScreen';
import ThreeSearchScreen from './Screens/ThreeSearchScreen';
import MasterScreen from './Screens/MasterScreen';
import ThreeMasterScreen from './Screens/ThreeMasterScreen';
import ThreeAdminInput from './Screens/ThreeAdminInput';
import ThreeAdminBuyInput from './Screens/ThreeAdminBuyInput';
import ThreeHotInput from './Screens/ThreeHotInput';
import ThreeSearchInput from './Screens/ThreeSearchInput';
import AdminHomeInput from './Screens/AdminHomeInput';
import AdminBuyInput from './Screens/AdminBuyInput';
import SearchInput from './Screens/SearchInput';
import HotInput from './Screens/HotInput';
import AdminWeeklyProfitLoss from './Screens/AdminWeeklyProfitLoss';
import NotfoundScreen from './Screens/NotfoundScreen';
import ThreeSummary from './Screens/ThreeSummary';
import AdminBuyEdit from './Screens/AdminBuyEdit';
import ThreeAdminBuyEdit from './Screens/ThreeAdminBuyEdit';

function App() {
  const [authTokens, setAuthTokens] = useState(
    localStorage.getItem('tokens') || ""
  )
  const [userType, setUserType] = useState(
    JSON.parse(localStorage.getItem('userType')) || ""
  )
  const [kind, setKind] = useState(
    localStorage.getItem('kind') || ""
  )
  const [selLotteryDate, setSelDate] = useState(
    JSON.parse(localStorage.getItem('selDate')) || ""
  )
  const [threeDate, setSelThreeDate] = useState(
    JSON.parse(localStorage.getItem('selThreeDate')) || ""
  )
  const setTokens = (data) => {
    localStorage.setItem('tokens', data);
    setAuthTokens(data);
  }
  const setTypeUser = (data) => {
    localStorage.setItem('userType', JSON.stringify(data));
    setUserType(data);
  }
  const setLotteryDate = (data) => {
    localStorage.setItem('selDate', JSON.stringify(data))
    setSelDate(data)
  }
  const setThreeDDate = (data) => {
    localStorage.setItem('selThreeDate', JSON.stringify(data))
    setSelThreeDate(data)
  }
  const setKindButton = (data) => {
    localStorage.setItem('kind', data);
    setKind(data)
    
  }
  var currentTime = moment().tz('Asia/Yangon').format('YYYY-MM-DD HH:mm:ss')
  useEffect(() => {
    if(authTokens){
      if(currentTime > moment(authTokens).add(moment.duration(5, "hours")).format('YYYY-MM-DD HH:mm:ss')) {
        handleLogout()
      }
    }
  },[authTokens, currentTime])
  const handleLogout = async() => {
    await localStorage.removeItem('tokens');
    await localStorage.removeItem('userType');
    await localStorage.removeItem('selDate');
    await localStorage.removeItem('kind');
    await localStorage.removeItem('selThreeDate');
    setKind("");
    setAuthTokens("");
  }
  return (
    <div className="App">
      <AuthContext.Provider value={{handleLogout, authTokens, userType, selLotteryDate,
        setAuthTokens: setTokens, kind,
        setLotteryDate: setLotteryDate,
        setTypeUser:setTypeUser, setKindButton,
        threeDate, setThreeDDate
        }}>
        {kind === '2d'?
        <Router>
          <Routes>
            <Route
            path='/' element={authTokens? userType?.type==='master'?<MasterScreen/>: <SelectDateScreen/>: <LoginScreen/>}
            />
            <Route
            path='/home' element={userType?.type==='user'? selLotteryDate?.date === undefined? <SelectDateScreen/>:<HomeScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <AdminHomeScreen/>}
            />
            <Route
            path='/history'
            element={userType?.type==='admin'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <HistoryScreen/>}
            />
            <Route
            path='/details'
            element={userType?.type==='admin'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <DetailScreen/>}
            />
            <Route
            path='/profitloss'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <ProfitLossScreen/>}
            />
            <Route
            path='/adminbuy'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <AdminBuyScreen/>}
            />
            <Route
            path='/adminbuyhistory'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <AdminHistoryScreen/>}
            />
            <Route
            path='/weekly'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <AdminWeeklyProfitLoss/>}
            />
            <Route
            path='/summary'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <DailyScreen/>}
            />
            <Route
            path='/hotnumbers'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <HotScreen/>}
            />
            <Route
            path='/account'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <AccountScreen/>}
            />
            <Route
            path='/search'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <SearchScreen/>}
            />
            <Route
            path='/admininput'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <AdminHomeInput/>}
            />
            <Route
            path='/buyinput'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <AdminBuyInput/>}
            />
            <Route
            path='/searchinput'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <SearchInput/>}
            />
            <Route
            path='/hotinput'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <HotInput/>}
            />
            <Route
            path='/buyedit'
            element={userType?.type==='user'? <NotfoundScreen/> : selLotteryDate?.date === undefined? <SelectDateScreen/> : <AdminBuyEdit/>}
            />
            <Route path="*" element={<NotfoundScreen/>} />
          </Routes>
        </Router>:
        kind === '3d'?
        <Router>
          <Routes>
            <Route
            path='/' element={authTokens? userType?.type==='master'?<ThreeMasterScreen/>: userType?.type === 'user'? <ThreeAgentSelectDateScreen/>:<ThreeSelectDateScreen/>: <LoginScreen/>}
            />
            <Route
            path='/home' element={userType?.type==='user'? threeDate?.dateFrom ===undefined? <ThreeAgentSelectDateScreen/> : <ThreeHomeScreen/>: threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/>:<ThreeAdminHomeScreen/>}
            />
            <Route
            path='/history'
            element={userType?.type==='admin'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeAgentSelectDateScreen/> : <ThreeHistoryScreen/>}
            />
            <Route
            path='/details'
            element={userType?.type==='admin'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeAgentSelectDateScreen/> : <ThreeDetailScreen/>}
            />
            <Route
            path='/threeprofitloss'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeProfitLossScreen/>}
            />
            <Route
            path='/threeadminbuy'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeAdminBuyScreen/>}
            />
            <Route
            path='/threeadminbuyhistory'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeAdminHistoryScreen/>}
            />
            <Route
            path='/monthly'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeMonthlyScreen/>}
            />
            <Route
            path='/threesummary'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom === undefined? <ThreeSelectDateScreen/> : <ThreeSummary/>}
            />
            <Route
            path='/threehotnumbers'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeHotScreen/>}
            />
            <Route
            path='/threeaccount'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeAccountScreen/>}
            />
            <Route
            path='/search'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeSearchScreen/>}
            />
            <Route
            path='/threeinput'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeAdminInput/>}
            />
            <Route
            path='/threebuyinput'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeAdminBuyInput/>}
            />
            <Route
            path='/threehotinput'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeHotInput/>}
            />
            <Route
            path='/threesearchinput'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeSearchInput/>}
            />
            <Route
            path='/threebuyedit'
            element={userType?.type==='user'? <NotfoundScreen/> : threeDate?.dateFrom ===undefined? <ThreeSelectDateScreen/> : <ThreeAdminBuyEdit/>}
            />
            <Route path="*" element={<NotfoundScreen/>} />
          </Routes>
        </Router>:
        <SelectScreen/>
        }
      </AuthContext.Provider>
    </div>
  );
}

export default App;
